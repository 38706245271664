import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL, API_URL } from '../../../../src/tokens';
import { Auth } from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class ApiRedirectInterceptor implements HttpInterceptor {
  private swapUrl: boolean;
  private jwtToken: string;

  constructor(@Inject(BASE_URL) baseUrl: string, @Inject(API_URL) private apiUrl: string) {
    this.swapUrl = baseUrl !== apiUrl;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // refresh jwt token (if needed)
    Auth.currentSession().then(
      (res) => {
        const idToken = res.getIdToken();
        this.jwtToken = idToken.getJwtToken();
      },
      (failure) => {},
    );

    let url = req.url?.indexOf(this.apiUrl) > -1 ? new URL(req.url).pathname : req.url;
    url = url.startsWith('/') ? url.slice(1) : url; // remove leading slash

    if (url !== undefined && url.toLowerCase().startsWith('api/')) {
      req = req.clone({
        url: this.swapUrl ? this.apiUrl + url : url,
        headers: req.headers.set('Authorization', `Bearer ${this.jwtToken}`),
      });
    }

    return next.handle(req);
  }
}
