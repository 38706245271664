import { Injectable } from '@angular/core';
import { IAuthService } from '../interfaces/auth-service.interface';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Hub } from '@aws-amplify/core';
import { debug } from '../core/rxjs/debug-operator';
import { ElephantUser } from '../models/elephant-user.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService {
  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isLoggingIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userProfile: BehaviorSubject<ElephantUser> = new BehaviorSubject<ElephantUser>(undefined);
  config = environment.cognitoProperties;
  isAuthenticated$: Observable<boolean> = this.isAuthenticated.asObservable();
  isLoggingIn$: Observable<boolean> = this.isLoggingIn.asObservable();
  userProfile$: Observable<ElephantUser> = this.userProfile.asObservable();
  loggedIn: boolean;

  constructor(private router: Router) {
    Auth.configure({
      userPoolId: this.config.userPoolId,
      userPoolWebClientId: this.config.userPoolWebClientId,
      region: this.config.region,
      oauth: {
        domain: this.config.domain,
        redirectSignIn: window.location.origin + '/',
        redirectSignOut: window.location.origin + '/',
        responseType: 'code',
        scope: [
          'email',
          'openid',
          'profile',
          'aws.cognito.signin.user.admin', // needed to get user attributes
        ],
      },
    });

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          break;
        case 'signOut':
        case 'oAuthSignOut':
          this.isAuthenticated.next(false);
          this.loggedIn = false;
          break;
        default:
          debug(`unhandled event ${event}: ${data}`);
      }
    });

    // this is just to trigger authentication
    Auth.currentAuthenticatedUser().then(
      (user: CognitoUser) => {
        if (user === undefined) return;

        this.loginAndCreateElephantUser(user).then();
      },
      (failure) => {},
    );
  }

  login(url: string = '/'): void {
    // store redirectUri as localStorage as we will loose it on
    this.isLoggingIn.next(true);

    localStorage.setItem('auth-service-redirectUri', url);
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  }
  logout(): void {
    Auth.signOut().then(() => {
      window.location.href = window.location.origin;
    });
  }

  private async loginAndCreateElephantUser(user: CognitoUser) {
    this.isAuthenticated.next(true);
    this.loggedIn = true;

    // get user profile
    user.getUserAttributes((error, userAttributes: CognitoUserAttribute[]) => {
      if (error) {
        console.log('Error white getting user attributes: ' + error);
        return;
      }

      const elephantUser = new ElephantUser(user, userAttributes);
      this.userProfile.next(elephantUser);

      // verify that user is admin or user
      if (!elephantUser.isAdmin && !elephantUser.isUser) {
        return this.router.navigate(['missing-roles']);
      }

      const redirectUri = localStorage.getItem('auth-service-redirectUri');
      // navigate to url that user was on before login
      if (redirectUri !== null) {
        this.router.navigateByUrl(redirectUri);
      }
    });
  }
}
