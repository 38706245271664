import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

const production = environment.production;

export function debug<T>(message: string, property?: string) {
  if (production) {
    return tap<T>();
  }
  return tap<T>((x) => {
    if (property) {
      return console.log(message, x[property]);
    }
    console.log(message, x);
  });
}
