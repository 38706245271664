import {
  AfterContentChecked,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatRadioButton } from '@angular/material/radio';
import { BehaviorSubject } from 'rxjs';

/**
 * RadioGroupCompareWithDirective is a custom AttributeDirective allowing the setting of Controls
 *
 * mat-radio is misisng 'compareWith'. Try to get correct reference with same name/parent.
 * see https://github.com/angular/components/issues/10495
 * @template T
 */
@Directive({
  selector: 'mat-radio-group[appCompareWith]',
})
export class RadioGroupComparehDirective<T> implements OnChanges, OnDestroy, AfterContentChecked {
  constructor(private cdref: ChangeDetectorRef) {}

  @Input() appCompareWith: (o1: T, o2: T) => boolean;
  @Input() formControl: UntypedFormControl;

  @ContentChildren(MatRadioButton, { descendants: true }) radioButtons: QueryList<MatRadioButton>; // List of descendant RadioButtons

  ngOnChangesModel = new BehaviorSubject<T>(null);

  private modelChangeSubscription;

  ngAfterContentChecked(): void {
    this.setSelectedChild();
  }

  setSelectedChild() {
    this.modelChangeSubscription = this.ngOnChangesModel.subscribe(() => {
      const foundRadioButton = this.radioButtons.toArray().find((radioButton) => {
        // Find a radio button whose value compares to ngModel
        return this.appCompareWith(radioButton.value, this.formControl.value);
      });
      if (foundRadioButton) {
        // If radio button is found
        if (this.formControl.value !== foundRadioButton.value) {
          // But its value is not already the ngModel
          this.formControl.patchValue(foundRadioButton.value, { emitEvent: false, onlySelf: true });
          this.cdref.detectChanges();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ngModel) {
      this.ngOnChangesModel.next(changes.ngModel.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.modelChangeSubscription) {
      this.modelChangeSubscription.unsubscribe();
    }
  }
}
