import { NgModule } from '@angular/core';

import { NavComponent } from './nav/nav.component';
import { Logger } from './logger.service';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FeedbackModule } from '../feedback/feedback.module';

@NgModule({
  imports: [AppRoutingModule, SharedModule, FeedbackModule],
  exports: [NavComponent],
  declarations: [NavComponent],
  providers: [Logger],
})
export class CoreModule {}
