import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.css'],
})
export class RenameDialogComponent {
  public name: string = '';
  constructor(private dialogRef: MatDialogRef<RenameDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.name = data?.name ?? '';
  }

  confirm() {
    if (this.name) {
      this.dialogRef.close({ success: true, name: this.name });
    } else {
      this.cancel();
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
