import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { AppMaterialModule } from '../app-material/app-material.module';
import { ColoredCheckboxDirective } from './directives/colored-checkbox/colored-checkbox.directive';
import { EnumKeyValueListPipe } from './pipes/enum-key-value-list.pipe';
import { BannerComponent } from './components/banner/banner.component';
import { LinkWithAuthorizationDirective } from './directives/link-with-authorization/link-with-authorization.directive';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { RenameDialogComponent } from './components/rename-dialog/rename-dialog.component';

@NgModule({
  imports: [CommonModule, AppMaterialModule, FlexLayoutModule],
  exports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    LayoutModule,
    ReactiveFormsModule,

    ColoredCheckboxDirective,
    LinkWithAuthorizationDirective,

    EnumKeyValueListPipe,

    BannerComponent,
    ConfirmationDialogComponent,
  ],
  declarations: [
    ConfirmationDialogComponent,
    BannerComponent,
    ColoredCheckboxDirective,
    LinkWithAuthorizationDirective,
    EnumKeyValueListPipe,
    RenameDialogComponent,
  ],
})
export class SharedModule {}
