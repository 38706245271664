<nav class="nav-header docs-nav-header primary">
  <span class="nav-element-wrapper">
    <a class="nav-brand" [routerLink]="['']" style="display: flex">
      <mat-icon svgIcon="hiab2x2" class="hiab-logo"></mat-icon>
    </a>
  </span>
  <div fxFlex></div>

  <div *ngIf="(authService.isLoggingIn$ | async); else notloading">
    <button mat-button color="primary" disabled>
      <mat-spinner diameter="24" color="accent"></mat-spinner>
      Log In
    </button>

    </div>
    <ng-template #notloading>
      <button *ngIf="!authService.loggedIn; else loggedOut" mat-button color="accent" (click)="authService.login()">
        Log In
    </button>
    <ng-template #loggedOut>
        <button mat-button color="accent" (click)="authService.logout()" [matTooltip]="(authService.userProfile$ | async)?.getName()">
            <img class="avatar" [src]="(authService.userProfile$ | async)?.picture" [alt]="(authService.userProfile$ | async)?.getName()">
            Log Out
        </button>
    </ng-template>
    </ng-template>

  <button feedback mat-icon-button aria-label="Send feedback"
    class="mat-elevation-z5 feedback-button" matTooltip="Send feedback" matTooltipPosition="below" (send)="onSend($event)">
    <mat-icon>feedback</mat-icon>
  </button>
  <a
    [attr.href]="documentationUri"
    mat-icon-button
    class="mat-elevation-z5 documentation-button"
    matTooltip="Show documentation"
    matTooltipPosition="below"
    target="_blank"
  >
    <mat-icon>menu_book</mat-icon>
  </a>
</nav>
