import { Component, OnInit, Input } from '@angular/core';
import { Banner } from './banner.model';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
})
export class BannerComponent implements OnInit {
  @Input() banners: Banner[];

  constructor() {}

  ngOnInit(): void {}

  okAction(banner: Banner) {
    if (this.isFunction(banner.okAction)) {
      banner.okAction();
    }

    this.removeBanner(banner);
  }

  cancelAction(banner: Banner) {
    if (this.isFunction(banner.cancelAction)) {
      banner.cancelAction();
    }

    this.removeBanner(banner);
  }

  private removeBanner(banner: Banner) {
    const index = this.banners.findIndex((x) => x === banner);
    this.banners.splice(index, 1);
  }

  private isFunction(possibleFunction) {
    return typeof possibleFunction === typeof Function;
  }
}
