import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ProjectService } from '../../services/project.service';
import { Project } from '../models/project.model';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ProjectHttpService } from '../http-services/project-http.service';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.css'],
})
export class NewProjectComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() description: string;

  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
  });

  newProject = true;

  saveProjectSub: Subscription;

  constructor(
    private projectHttp: ProjectHttpService,
    private projectService: ProjectService,
    private snackbar: MatSnackBar,
    private router: Router,
  ) {}

  ngOnChanges() {
    this.newProject = false;
  }

  ngOnInit() {}

  onSubmit({ value, valid }: { value: Project; valid: boolean }) {
    if (!valid) {
      return;
    }

    if (this.saveProjectSub) {
      this.saveProjectSub.unsubscribe();
    }

    this.saveProjectSub = this.projectHttp.saveProject(value).subscribe(
      (data) => {
        this.projectService.setProjectAction(data);
        this.router.navigate(['project', data.id, 'add']);
      },
      (err) => {
        this.snackbar.open('Could not add project');
        setTimeout((x) => {
          this.snackbar.dismiss();
        }, 500);
      },
    );
  }

  cancel() {
    this.router.navigate(['']);
  }
}
