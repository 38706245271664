<ng-container *ngIf="(projects | async)?.length > 0; else elseTemplate">
  <mat-card>
    <mat-card-title>Projects</mat-card-title>
    <div fxLayout="column" fxLayoutGap="15px">
      <div fxFlex>
        <button mat-raised-button (click)="newProject()">New Project</button>
      </div>
      <div fxFlex>
        <ng-container *ngIf="(projects | async) as projects">
          <mat-card *ngFor="let project of projects" fxLayout="row" fxLayoutAlign="start">
            <div fxFlex="90" (click)="openProject(project)">
              <h2>{{project.name}}</h2>
              <p>{{project.description}}</p>
              <p>{{dateToString(project.updatedAt)}}</p>
            </div>
            <div fxFlex>
              <button mat-raised-button (click)="deleteProject(project)" class="delete-button">Delete</button>
            </div>
          </mat-card>
        </ng-container>
      </div>
    </div>
  </mat-card>
</ng-container>

<ng-template #elseTemplate>
  <p>Create a project to get started</p>
  <app-new-project></app-new-project>
</ng-template>
