import { Directive, HostListener } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Directive({
  selector: '[link-with-authorization]',
})
/**
 * Directive which overrides default href click behaviour and adds an authorization header to link in order to be able to download external links requiring authorization
 * as well as downloads the linked file and returns it as a download in the browser
 */
export class LinkWithAuthorizationDirective {
  constructor(private http: HttpClient) {}
  @HostListener('click', ['$event'])
  onClick(e) {
    e.preventDefault();
    e.stopPropagation();

    let element = e.target;
    let iterations: number = 0;
    while (element.nodeName != 'A' && iterations < 5) {
      element = element.parentElement;
      iterations++;
    }
    const filename = element.href;

    firstValueFrom(this.http.get<Blob>(filename, { observe: 'response', responseType: 'blob' as 'json' })).then(
      (response: HttpResponse<Blob>) => {
        let data = [];
        data.push(response.body);
        const file = new Blob(data, { type: response.type.toString() });
        let filename: string = this.getFileName(response);

        // trigger a browser download with downloaded file
        let downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(file);
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
    );
  }

  // gets filename from content-disposition header returned from server
  getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get('content-disposition');
      const r = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
      filename = r.exec(contentDisposition)[1];
    } catch (e) {
      filename = 'myfile.txt';
    }
    return filename;
  }
}
