<app-nav></app-nav>

<div class="container">
    <div *ngIf="(authService.isLoggingIn$ | async)" class="logging-in-container">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Login</mat-card-title>
                <mat-card-subtitle>Checking</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-spinner diameter="96" color="accent"></mat-spinner>
            </mat-card-content>
        </mat-card>
    </div>
  <router-outlet></router-outlet>
</div>
