import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Observable, firstValueFrom } from 'rxjs';
import { Project } from '../models/project.model';
import { Router } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { ProjectHttpService } from '../http-services/project-http.service';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css'],
})
export class ProjectListComponent implements OnInit {
  projects: Observable<Project[]>;
  dialogConfig: MatDialogConfig;

  constructor(
    private dialog: MatDialog,
    private projectHttp: ProjectHttpService,
    private projectService: ProjectService,
    private router: Router,
  ) {
    this.projects = projectHttp.getProjects();
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.width = '250px';
  }

  ngOnInit() {}

  newProject(): void {
    this.router.navigate(['projects', 'add']);
  }

  deleteProject(project: Project): void {
    this.dialogConfig.data = {
      title: 'Delete project?',
      description: `Do you really want to remove the project ${project.name} ?`,
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe((data: boolean) => {
      if (data) {
        firstValueFrom(this.projectHttp.deleteProject(project.id)).then((result) => {
          if (result) {
            this.projects = this.projectHttp.getProjects();
          }
        });
      }
    });
  }

  dateToString(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd HH:mm', 'en-US');
  }

  openProject(project: Project): void {
    if (!project) {
      return;
    }

    this.projectService.setProjectAction(project);

    this.router.navigate(['project', project.id]);
  }
}
