export const sharedUrls = {
  Api: 'api',
  ApiCheckMachineResult: 'api/check-machine-result',
  ApiOfficial: 'api/Official',
  ApiSimulation: 'api/simulation',
  ApiWorkingRegion: 'api/working-region',
  ApiVisualization: 'api/visualize',
  ApiCraneStandards: 'api/crane-standard',
  ApiCut: 'api/cut',
  ApiDmt: 'api/dmt',
  ApiStaticFiles: 'api/static',
  ApiProject: 'api/project',
  ApiProjectRoot: 'api/root',
  ApiCraneState: 'api/state',
  ApiPositionsSetup: 'api/positions-setup',
  ApiCalculationSetup: 'api/calculation-setup',
  ApiBatch: 'api/CraneBatch',
  ApiSinglePositionResult: 'api/SinglePositionResult',
  ApiLoadCurve: 'api/load-curve',
  ApiLoadCurveSettings: 'api/load-curve-setting',
  ApiAssemble: 'api/assemble',
  ApiAliasConnection: 'api/alias-connection',
  ApiOptionTag: 'api/option-tag',
  ApiExportImport: 'api/export-import',
  ApiUserSettings: 'api/user-settings',
};
