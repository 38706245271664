import { Directive, Renderer2, ElementRef, OnInit, Input, OnChanges } from '@angular/core';

/**
 * This directive is mainly used for showing different colours on checkboxes in the crane nodes after a calculation has been completed,
 * in order to be able to match results in project view with results in the result page.
 */
@Directive({
  selector: '[appColoredCheckbox]',
})
export class ColoredCheckboxDirective implements OnInit, OnChanges {
  @Input() checkboxColor: string;
  @Input() checkboxChecked: boolean;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.setColor();
  }

  ngOnChanges(): void {
    this.setColor();
  }

  private setColor() {
    const nativeElement = this.el.nativeElement;
    const ripples = nativeElement.querySelectorAll('.mat-ripple');
    const backgrounds = nativeElement.querySelectorAll('.mdc-checkbox__background');

    if (!this.checkboxColor) {
      this.renderer.removeStyle(backgrounds[0], 'background-color');
      this.renderer.removeStyle(ripples[0], 'border-color');
      return;
    }

    if (!this.checkboxChecked) {
      this.renderer.setStyle(ripples[0], 'border-color', this.checkboxColor);
      this.renderer.removeStyle(backgrounds[0], 'background-color');
    } else {
      this.renderer.removeStyle(ripples[0], 'border-color');
      this.renderer.setStyle(backgrounds[0], 'background-color', this.checkboxColor);
    }
  }
}
