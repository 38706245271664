import { NgModule } from '@angular/core';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { AngularSplitModule } from 'angular-split';
import { RadioGroupComparehDirective } from './app-mat-radio-group-compare.directive';

@NgModule({
  imports: [],
  exports: [
    MatAutocompleteModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatTreeModule,
    MatTabsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatTooltipModule,
    AngularSplitModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatDialogModule,
    RadioGroupComparehDirective,
  ],
  declarations: [RadioGroupComparehDirective],
})
export class AppMaterialModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('cuts', domSanitizer.bypassSecurityTrustResourceUrl('/assets/cuts.svg'));

    matIconRegistry.addSvgIcon(
      'calculation_values',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/calculation-values.svg'),
    );

    matIconRegistry.addSvgIcon('optimal_pos', domSanitizer.bypassSecurityTrustResourceUrl('/assets/optimal-pos.svg'));

    matIconRegistry.addSvgIcon('scan_pos', domSanitizer.bypassSecurityTrustResourceUrl('/assets/scan-pos.svg'));

    matIconRegistry.addSvgIcon('single_pos', domSanitizer.bypassSecurityTrustResourceUrl('/assets/single-pos.svg'));

    matIconRegistry.addSvgIcon('setup', domSanitizer.bypassSecurityTrustResourceUrl('/assets/standards-setup.svg'));

    matIconRegistry.addSvgIcon(
      'working_region',
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/working-region.svg'),
    );

    matIconRegistry.addSvgIcon('load_curve', domSanitizer.bypassSecurityTrustResourceUrl('/assets/one-load-curve.svg'));

    matIconRegistry.addSvgIcon('state', domSanitizer.bypassSecurityTrustResourceUrl('/assets/vertical-setup.svg'));

    matIconRegistry.addSvgIcon('no_results', domSanitizer.bypassSecurityTrustResourceUrl('/assets/no-results.svg'));

    matIconRegistry.addSvgIcon('crane', domSanitizer.bypassSecurityTrustResourceUrl('/assets/crane.svg'));

    matIconRegistry.addSvgIcon('puzzle', domSanitizer.bypassSecurityTrustResourceUrl('/assets/puzzle.svg'));

    matIconRegistry.addSvgIcon('wind', domSanitizer.bypassSecurityTrustResourceUrl('/assets/wind.svg'));

    matIconRegistry.addSvgIcon('hiab2x2', domSanitizer.bypassSecurityTrustResourceUrl('/assets/hiab2x2.svg'));
  }
}
