<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.description}}</p>
  <mat-form-field focused="true">
    <mat-label>New Name</mat-label>
    <input matInput placeholder="Enter new name.." [value]="name" (input)="name = $event.target.value"
      (keyup.enter)="confirm()" onfocus="this.select()" required>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <div class="buttonDiv">
    <button mat-raised-button mat-dialog-close (click)="cancel()">Cancel</button>
    <button color="primary" mat-raised-button mat-dialog-close (click)="confirm()" [disabled]="!name">Ok</button>
  </div>
</div>