import { AuthProvider } from '../app/models/auth-provider.enum';
import { sharedUrls } from './sharedUrls';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

export const environment = {
  api_url_prefix: process.env.API_URL_PREFIX,
  production: true,
  urls: sharedUrls,
  documentationUri: 'https://docs.dev.elephant.hiab.com/',
  cognitoProperties: {
    userPoolId: 'eu-central-1_QIHwHXnSX',
    userPoolWebClientId: '74f7dcfv87tv2b4u671qrbr5cc',
    region: 'eu-central-1',
    domain: 'cognito.dev.elephant.hiab.com',
  },
  authProvider: AuthProvider.Cognito,
  schemaUrl: 'https://schema.dev.elephant.hiab.com',
};
