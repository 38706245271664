import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CallbackComponent } from './callback/callback.component';
import { CoreModule } from './core/core.module';
import { ApiRedirectInterceptor } from './core/interceptor/api-redirect.interceptor';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MissingRolesComponent } from './missing-roles/missing-roles.component';
import { ProjectsModule } from './projects/projects.module';
import { SharedModule } from './shared/shared.module';
import { ProboscisViewerModule } from '@hiab_lclm/proboscis-viewer';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { LocalhostAuthService } from './services/localhost-auth.service';
import { AuthProvider } from './models/auth-provider.enum';

const AuthFactory = (router: Router) => {
  // needed for tests
  if (environment.authProvider == AuthProvider.Local) {
    return new LocalhostAuthService();
  }

  return new AuthService(router);
};

@NgModule({
  declarations: [AppComponent, CallbackComponent, MissingRolesComponent, LandingPageComponent],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    SharedModule,
    ProjectsModule,
    ProboscisViewerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRedirectInterceptor,
      multi: true,
    },
    {
      provide: AuthService,
      useFactory: AuthFactory,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
