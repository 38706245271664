import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CallbackComponent } from './callback/callback.component';
import { MissingRolesComponent } from './missing-roles/missing-roles.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent, pathMatch: 'full' },
  { path: 'callback', component: CallbackComponent },
  { path: 'missing-roles', component: MissingRolesComponent },
  { path: 'project', loadChildren: () => import('./project/project.module').then((m) => m.ProjectModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
