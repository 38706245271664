import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ModelDescriptionDTO, MachineDTO } from '../../models/machine.model';
import { OfficialCrane } from '../../project/models/offical-crane.model';
import { Project } from '../models/project.model';
import { PositionsSetupDTO } from '../../project/models/positions-setup.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProjectHttpService {
  constructor(private http: HttpClient) {}

  getProject(projectId: number): Observable<Project> {
    return this.http.get<Project>(`${environment.urls.ApiProject}/${projectId}`);
  }

  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(`${environment.urls.ApiProject}`);
  }

  saveProject(project: Project): Observable<Project> {
    return this.http.post<Project>(`${environment.urls.ApiProject}`, project);
  }

  deleteProject(projectId: number): Observable<boolean> {
    return this.http.delete<Project>(`${environment.urls.ApiProject}/${projectId}`).pipe(
      map((result) => {
        if (!result) return false;

        return true;
      }),
    );
  }

  // TODO: convert the saved object to correct object type before calling this function
  // Then we could redirect user and start working and just do damage control if the save dosen´t work.
  // Issue: #773
  saveMachine(projectId: number, craneDesign: OfficialCrane) {
    return this.http.post<MachineDTO>(`${environment.urls.ApiProjectRoot}/${projectId}`, craneDesign);
  }

  deleteMachine(machineId: number): Observable<boolean> {
    return this.http.delete<MachineDTO>(`${environment.urls.ApiProjectRoot}/${machineId}`).pipe(
      map((deletedMachine) => {
        if (!deletedMachine) return false;

        return true;
      }),
    );
  }

  getMachines(projectId: number): Observable<MachineDTO[]> {
    return this.http.get<MachineDTO[]>(`${environment.urls.ApiProjectRoot}`, {
      params: { projectId: projectId.toString() },
    });
  }

  invalidateMachineData(projectRootId: number): Observable<void> {
    return this.http.patch<void>(`${environment.urls.ApiProjectRoot}`, projectRootId);
  }

  getModels(projectId: number): Observable<ModelDescriptionDTO[]> {
    return this.http.get<ModelDescriptionDTO[]>(`${environment.urls.ApiProjectRoot}/${projectId}/designs`);
  }

  saveCranePosition(cranePosition: PositionsSetupDTO): Observable<PositionsSetupDTO> {
    return this.http.post<PositionsSetupDTO>(`${environment.urls.ApiPositionsSetup}`, cranePosition);
  }
}
