import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IAuthService } from '../interfaces/auth-service.interface';
import { ElephantUser } from '../models/elephant-user.model';
@Injectable({
  providedIn: 'root',
})
export class LocalhostAuthService implements IAuthService {
  constructor() {}
  userProfile$: Observable<ElephantUser> = of({
    givenName: 'Bob',
    familyName: 'Builder',
    email: 'bob.builder@hiab.com',
    picture: '',
    isAdmin: true,
    isUser: true,
    getName() {
      return 'Bob Builder';
    },
  });
  isAuthenticated$: Observable<boolean> = of(true);
  isLoggingIn$: Observable<boolean> = of(false);
  loggedIn = true;
  login(): void {
    throw new Error('Method not implemented.');
  }
  logout(): void {
    throw new Error('Method not implemented.');
  }
}
