import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'app';
  private version = '0.8.3';

  constructor(public authService: AuthService) {
    this.validateVersion();
  }

  validateVersion() {
    const userVersion = localStorage.getItem('version');

    if (userVersion !== this.version) {
      localStorage.clear();
      localStorage.setItem('version', this.version);
    }
  }
}
