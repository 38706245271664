<mat-card class="card-fullscreen">
  <h1>Missing permissions!</h1>
  <p>You are currently missing permissions:</p>
  <ul class="information-table">
    <li>
      <b>You are a new user</b><br/>
      Please <a href="mailto:dl.elephantsupport@hiab.com?subject=New user requesting access to Elephant" target="noopener noreferrer">email the Elephant team</a> and request permissions <br/>
      The Elephant team will contact you after reviewing your request.
    </li>
    <li>
      <b>You are a returning user</b><br/>
      You have lost your roles. Please <a href="mailto:dl.elephantsupport@hiab.com?subject=I have lost access to elephant" target="noopener noreferrer">email the Elephant team.</a><br/>
    </li>

  </ul>
  <mat-card>
      <mat-card-title>Contact information:</mat-card-title>
    <mat-card-content><a href="mailto:dl.elephantsupport&#64;hiab.com?subject=Missing roles"target="noopener noreferrer">dl.elephantsupport&#64;hiab.com</a></mat-card-content>
  </mat-card>

</mat-card>
