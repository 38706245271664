<mat-card>
  <mat-card-title>New Project</mat-card-title>
  <form fxLayout="column" [formGroup]="form" (ngSubmit)="onSubmit(form)" fxLayoutGap="15px" fxLayoutAlign="start stretch">
    <mat-form-field fxFlex>
      <input matInput placeholder="Name" formControlName="name" required>
    </mat-form-field>

    <mat-form-field fxFlex>
      <input matInput placeholder="Description" formControlName="description">
    </mat-form-field>

    <div fxFlex>
      <div fxLayout="row" fxLayoutAlign="start start">

        <div fxFlex>
          <button type="submit" mat-raised-button [disabled]="!form.valid">{{newProject ? 'Create' : 'Update'}}</button>
        </div>
        <div fxFlex="grow"></div>
        <div fxFlex>
          <button mat-raised-button (click)="cancel()">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</mat-card>
