 <ng-container *ngIf="!!banners && banners.length > 0">
  <div fxLayout="column" fxLayoutAlign="space-between stretch" *ngFor="let banner of banners" style="width: 100%; display: block;">

    <div fxLayout="column" fxLayoutAlign="space-evenly none" class="banner">
      <div fxLayout="row" class="text">
        {{banner.text}}
      </div>
      <div fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="6px">
        <button mat-stroked-button *ngIf="banner.okAction" (click)="okAction(banner)" class="ok">{{banner.okActionText}}</button>
        <button mat-stroked-button *ngIf="banner.cancelAction" (click)="cancelAction(banner)" class="cancel">{{banner.cancelActionText}}</button>
      </div>
    </div>
  </div>
</ng-container>
