import { environment } from './environments/environment';
import { InjectionToken } from '@angular/core';

export const BASE_URL = new InjectionToken<string>('BASE_URL');
export const API_URL = new InjectionToken<string>('API_URL');

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

export function getAPIUrl() {
  let url = getBaseUrl();

  if (environment.api_url_prefix) {
    let index = url.indexOf('://') + 3;

    let newUrl = url.slice(0, index) + environment.api_url_prefix + '.' + url.slice(index);

    return newUrl;
  }

  return url;
}
