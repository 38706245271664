import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-missing-roles',
  templateUrl: './missing-roles.component.html',
  styleUrls: ['./missing-roles.component.css'],
})
export class MissingRolesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
