import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Logger } from '../../core/logger.service';
import { environment } from '../../../environments/environment';
import { retry, catchError, share } from 'rxjs/operators';
import { CmfComponent } from '../../models/cmf-component.model';
import { ProjectFileRelationDTO } from '../dto/project-file-relation.model';
import { LoadAttachmentDTO } from '../../dto/crane-state.dto';

@Injectable({
  providedIn: 'root',
})
export class ProjectComponentHttpService {
  private observables = new Map<number, Observable<CmfComponent>>();

  constructor(private httpClient: HttpClient, private logger: Logger) {}

  /**
   * Gets component structure for a projectRoot
   * @param projectRootId
   * @returns ProjectFileRelationDTO[]
   */
  GetComponentStructure(projectRootId: number): Observable<ProjectFileRelationDTO[]> {
    return this.httpClient
      .get<ProjectFileRelationDTO[]>(`${environment.urls.ApiProjectRoot}/${projectRootId}/component-structure`)
      .pipe(share());
  }

  /**
   * Gets component structure for a component
   * @param projectRootId
   * @returns ProjectFileRelationDTO[]
   */
  GetComponentStructureForComponent(componentId: number): Observable<ProjectFileRelationDTO[]> {
    return this.httpClient
      .get<ProjectFileRelationDTO[]>(`${environment.urls.ApiProject}/component/${componentId}/component-structure`)
      .pipe(share());
  }

  /**
   * Gets load attachments for a projectRoot
   * @param projectRootId
   * @returns LoadAttachmentDTO[]
   */
  GetLoadAttachments(projectRootId: number): Observable<LoadAttachmentDTO[]> {
    return this.httpClient
      .get<LoadAttachmentDTO[]>(`${environment.urls.ApiProjectRoot}/${projectRootId}/load-attachments`)
      .pipe(share());
  }

  /**
   * Gets hoist rope names for a projectRoot
   * @param projectRootId
   * @returns string[]
   */
  GetHoistRopeNames(projectRootId: number): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${environment.urls.ApiProjectRoot}/${projectRootId}/hoist-ropes`)
      .pipe(share());
  }

  /**
   * Gets option tag names for a projectRoot
   * @param projectRootId
   * @returns string[]
   */
  GetOptionTagNames(projectRootId: number): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${environment.urls.ApiProjectRoot}/${projectRootId}/option-tags`)
      .pipe(share());
  }

  GetComponents(projectId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.urls.ApiProject}/${projectId}/components`).pipe(
      retry(3),
      catchError((x) => {
        console.error(`Could not fetch project files for project ${projectId}`);
        return of([]);
      }),
    );
  }

  /**
   * Gets cmf component
   * Caches obserables and CmfComponents
   * @param projectFileId
   * @returns cmf component
   */
  GetComponent(projectFileId: any): Observable<any> {
    if (this.observables.has(projectFileId)) {
      return this.observables.get(projectFileId);
    }

    const observable = this.httpClient.get<any>(`${environment.urls.ApiProject}/component/${projectFileId}`).pipe(
      retry(3),
      catchError((x) => this.handleError(x, projectFileId.toString())),
      share(),
    );

    this.observables.set(projectFileId, observable);

    return observable;
  }

  /**
   * Updates the CmfComponent on the server.
   * Also updates the cached CmfComponent with the new values
   * @param projectFileId
   * @param component
   */
  Update(projectFileId: number, component: object): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const url = `${environment.urls.ApiProject}/component/${projectFileId}`;

    return this.httpClient.put<any>(url, component, { headers }).pipe(
      catchError((x) => {
        console.error(`Could not update component with id ${projectFileId}`, component);
        return of({});
      }),
    );
  }

  private handleError(error: HttpErrorResponse, componentId: string) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(`Could not get file with id: ${componentId}.`);
  }
}
