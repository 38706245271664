<div fxLayout="column" fxLayoutGap="12px" fxLayoutAlign="start center">
  <div class="landing-page" fxflex>
    <h1>Welcome to Elephant</h1>
    <p>
      Get started with the application by signing in with your google account. (Top right corner)<br />
      If you are a new user, <a href="mailto:dl.elephantsupport@hiab.com?subject=New user" target="noopener noreferrer">send an email to
        Elephant team</a> and we will give you permissions to run the application.
    </p>
  </div>
</div>
