import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { uploadFile, postMessage } from '../../feedback/slack';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  documentationUri: string;

  constructor(public authService: AuthService, private breakpointObserver: BreakpointObserver) {
    this.documentationUri = environment.documentationUri;
  }

  public async sendMessage(message: string, file: File | undefined) {
    if (file !== undefined) {
      await uploadFile(file, message);
    } else {
      await postMessage(message);
    }
  }
  public async onSend(val) {
    let file: File | undefined = undefined;
    if (val.screenshot) {
      const blob = await fetch(val.screenshot).then((it) => it.blob());
      file = new File([blob], 'screenshot.png');
    }

    let slackText: string = '';
    if (this.authService.loggedIn) {
      firstValueFrom(this.authService.userProfile$).then((user) => {
        slackText = `📣 Feedback: ${val.description}\n\nUser e-mail:${user.email}\nBrowser: ${navigator.userAgent}\nURL: ${document.URL}`;

        this.sendMessage(slackText, file);
      });
    } else {
      slackText = `📣 Feedback: ${val.description}\n\nBrowser: ${navigator.userAgent}\nURL: ${document.URL}`;

      await this.sendMessage(slackText, file);
    }
  }
}
