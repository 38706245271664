import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';

export class ElephantUser {
  givenName: string;
  familyName: string;
  email: string;
  picture: string;
  isAdmin: boolean = false;
  isUser: boolean = false;

  constructor(cognitoUser: CognitoUser, userAttributes?: CognitoUserAttribute[]) {
    if (userAttributes === undefined) return;

    let idToken = cognitoUser.getSignInUserSession().getIdToken();

    const groups: string[] = idToken.payload['cognito:groups'];
    if (groups !== undefined) {
      if (groups.indexOf('ElephantAdmins') > -1) {
        this.isAdmin = true;
        this.isUser = true;
      } else if (groups.indexOf('ElephantUsers') > -1) {
        this.isUser = true;
      }
    }

    userAttributes.forEach((attribute) => {
      switch (attribute.Name) {
        case 'given_name':
          this.givenName = attribute.Value;
          break;
        case 'family_name':
          this.familyName = attribute.Value;
          break;
        case 'email':
          this.email = attribute.Value;
          break;
        case 'picture':
          this.picture = attribute.Value;
        case 'email_verified':
        case 'sub':
        case 'identities':
          // ignored attribute(s)
          break;
        default:
          console.log('Unhandled user attribute: ' + attribute.Name);
      }
    });
  }

  getName(): string {
    return `${this.givenName} ${this.familyName}`;
  }
}
