import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { BASE_URL, API_URL, getAPIUrl, getBaseUrl } from './tokens';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const providers = [
  {
    provide: BASE_URL,
    useFactory: getBaseUrl,
    deps: [],
  },
  {
    provide: API_URL,
    useFactory: getAPIUrl,
    deps: [],
  },
];

https: if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
