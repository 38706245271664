import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  authSub: Subscription;
  constructor(authService: AuthService, route: Router) {
    this.authSub = authService.isAuthenticated$
      .pipe(filter((x) => !!x))
      .subscribe((x) => route.navigate(['/projects']));
  }
  ngOnInit() {}
  ngOnDestroy(): void {
    this.authSub.unsubscribe();
  }
}
