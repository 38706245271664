<div exclude-rect="true" class="rect" [ngStyle]="{'left.px': rectangle.startX,
                              'top.px': rectangle.startY,
                              'width.px': rectangle.width ,
                              'height.px': rectangle.height }"
    [ngClass]="{'highlight': rectangle.color === feedbackService.highlightedColor,
                'hide': rectangle.color !== feedbackService.highlightedColor,
                'noHover': noHover}">
  <span exclude-rect="true" class="close" *ngIf="showCloseTag" (click)="onClose()">
    <svg viewBox="0 0 1024 1024"
         width="16" height="16">
        <path
          d="M896 224l-96-96-288 288-288-288-96 96 288 288-288 288 96 96 288-288 288 288 96-96-288-288 288-288z"></path>
    </svg>
  </span>
</div>