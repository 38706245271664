import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectComponentHttpService } from '../project/http-services/project-component-http.service';
import { Project } from '../projects/models/project.model';
import { ProjectHttpService } from '../projects/http-services/project-http.service';
import { filter } from 'rxjs/operators';
import { PositionsSetupDTO } from '../project/models/positions-setup.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private initilizing = new BehaviorSubject(false);
  get projectInitilizing$(): Observable<boolean> {
    return this.initilizing.asObservable();
  }

  private selectedProject = new BehaviorSubject<Project>(null);
  get project(): Project {
    return this.selectedProject.getValue();
  }

  get project$(): Observable<Project> {
    return this.selectedProject.asObservable();
  }

  constructor(
    private projectComponentHttpService: ProjectComponentHttpService,
    private projectHttpService: ProjectHttpService,
  ) {}

  fetchProjectAction(projectId: number): void {
    if (!!this.project && this.project.id === projectId) {
      return;
    }

    this.initilizingProjectAction();
    this.projectHttpService
      .getProject(projectId)
      .pipe(filter((x) => !!x))
      .subscribe((project) => {
        this.setProjectAction(project);
        this.finishedinitilizingProjectAction();
      });
  }

  setProjectAction(project: Project): void {
    this.selectedProject.next(project);
  }

  initilizingProjectAction(): void {
    this.initilizing.next(true);
  }

  finishedinitilizingProjectAction(): void {
    this.initilizing.next(false);
  }
}
